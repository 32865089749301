import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {projectTitleInitialState} from '../reducers/project-title';
import downloadBlob from '../lib/download-blob';
import axios from "axios";

/**
 * Project saver component passes a downloadProject function to its child.
 * It expects this child to be a function with the signature
 *     function (downloadProject, props) {}
 * The component can then be used to attach project saving functionality
 * to any other component:
 *
 * <SB3Downloader>{(downloadProject, props) => (
 *     <MyCoolComponent
 *         onClick={downloadProject}
 *         {...props}
 *     />
 * )}</SB3Downloader>
 */
class SB3Downloader extends React.Component {
   
    constructor (props) {
        super(props);
        bindAll(this, [
            'downloadProject'
        ]);

        window.dlp  = this; 

    }

    //salva o projeto
    downloadProject (d1) {

        console.log(d1);
        
        this.props.saveProjectSb3().then(content => {

            if(this.props.onSaveFinished){

                this.props.onSaveFinished();

            }

            console.log('baixou arquivo '+this.props.projectFilename);
            console.log(content);

            console.log('vai');

            //
            var parse = window.parseQueryString();
            var pas = true;

            if(parse.instance === undefined){  console.log('instance não definido'); pas = false; }
            if(parse.user === undefined){  console.log('user não definido'); pas = false; }
            if(parse.project === undefined){  console.log('project não definido'); pas = false; }

            if(pas === true){

                if(parse.instance === ''){  console.log('instance em branco'); pas = false; }
                if(parse.user === ''){  console.log('user em branco'); pas = false; }
                if(parse.project === ''){  console.log('project em branco'); pas = false; }

            }

            if(pas === false){

                alert('Não foi possível salvar o projeto.');
                downloadBlob(this.props.projectFilename, content);
                return false;

            }


            if(d1 === true){

                console.log('baixou blob');
                downloadBlob(this.props.projectFilename,content);
                return false;

            }

            let data = new FormData();
            data.append('files',content);

            let config = {header:{'Content-Type':'multipart/form-data'}};

            axios.post('/api/upload?instance='+parse.instance+'&user='+parse.user+'&project='+parse.project+'',data,config).then(response => {

                console.log('response',response);
                alert('Arquivo salvo com sucesso.');

            }).catch(error => {

                console.log('error',error);
                alert('Não foi possível salvar.');

            })
  
        });

    }
    render () {
        const {
            children
        } = this.props;
        return children(
            this.props.className,
            this.downloadProject
        );
    }
}

const getProjectFilename = (curTitle, defaultTitle) => {
    let filenameTitle = curTitle;
    if (!filenameTitle || filenameTitle.length === 0) {
        filenameTitle = defaultTitle;
    }
    return `${filenameTitle.substring(0, 100)}.sb3`;
};

SB3Downloader.propTypes = {
    children: PropTypes.func,
    className: PropTypes.string,
    onSaveFinished: PropTypes.func,
    projectFilename: PropTypes.string,
    saveProjectSb3: PropTypes.func
};
SB3Downloader.defaultProps = {
    className: ''
};

const mapStateToProps = state => ({
    saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
    projectFilename: getProjectFilename(state.scratchGui.projectTitle, projectTitleInitialState)
});

export default connect(
    mapStateToProps,
    () => ({}) // omit dispatch prop
)(SB3Downloader);
